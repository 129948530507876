import styled from "@emotion/styled";
import { MAX_DESKTOP_CONTENT_WIDTH, MAX_DESKTOP_GRID_WIDTH, SM, XS_M } from "constants/vp-sizes";
export const StyledButtonTagsGridComponent = styled.div`
  max-width: ${MAX_DESKTOP_GRID_WIDTH}px;
  width: 100%;
  padding: 16px;
  margin: auto;

  .grid-button-list {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    margin: 0;
    padding: 0;

    .grid-button-container {
      width: 25%;
    }
  }

  @media (max-width: ${MAX_DESKTOP_CONTENT_WIDTH}px) {
    .grid-button-list {
      .grid-button-container {
        width: 33%;
      }
    }
  }

  @media (max-width: ${SM}px) {
    .grid-button-list {
      .grid-button-container {
        width: 50%;
      }
    }
  }

  @media (max-width: ${XS_M}px) {
    .grid-button-list {
      .grid-button-container {
        width: 100%;
        font-size: 0.875rem;
      }
    }
  }
`;
